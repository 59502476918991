const StockView = () => import('@/views/WareHouse/Stock/StockView')
const StockDetails = () => import('@/components/WareHouse/Stock/StockDetailsComponent')
const StockCountView = () => import('@/views/WareHouse/Stock/StockCountView')

export default [
    {
        path:'/StockIndex',
        name:'StockIndex',
        component:StockView,
        meta: {
            keepAlive: true
        }
    },
    {
        path:'/StockDetails',
        name:'StockDetails',
        component:StockDetails
    },
    {
        path:'/StockCount',
        name:'StockCount',
        component:StockCountView
    }
]