const ContractView = () => import('@/views/finance/Contract/ContractView')
const ContractDetails = () => import('@/components/finance/Contract/ContractDetailsComponent')

export default [
    {
        path:'/ContractIndex',
        name:'ContractIndex',
        component:ContractView,
    },
    {
        path:'/ContractDetails',
        name:'ContractDetails',
        component:ContractDetails
    }
]