const CurrencyView = () => import('@/views/finance/Currency/CurrencyView')
const CurrencyDetails = () => import('@/components/finance/Currency/CurrencyDetailsComponent')

export default [
    {
        path:'/CurrencyIndex',
        name:'CurrencyIndex',
        component:CurrencyView,
    },
    {
        path:'/CurrencyDetails',
        name:'CurrencyDetails',
        component:CurrencyDetails
    }
]