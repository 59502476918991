import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

//加载vant
import Vant from 'vant';
import 'vant/lib/index.css';

//加载自定义图标库
import './assets/icon/iconfont.css'

// 它会根据的手机尺寸来调整rem的基准值：html标签上的font-size。
import 'amfe-flexible'

//加载移动端初始化样式
import './assets/style/reset.css'

//加载微信JS-SDK
import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx

// wx.config({
//     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//     appId: '', // 必填，公众号的唯一标识
//     timestamp: '', // 必填，生成签名的时间戳
//     nonceStr: '', // 必填，生成签名的随机串
//     signature: '',// 必填，签名
//     jsApiList: [] // 必填，需要使用的JS接口列表
// });


//注册vant
Vue.use(Vant)

Vue.config.productionTip = false

//自定义权限检查指令
Vue.directive('has', {
  bind: (el, binding) => {
    if (!Vue.prototype.$_has(binding.value)) {
      el.style.display = 'none'
    }
  }
})

// 权限检查方法
Vue.prototype.$_has =  (value) => {
  return store.state.authList.indexOf(value) > -1
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
