const CarUseView = () => import('@/views/hrm/CarUse/CarUseView')
const CarUseDetails = () => import('@/components/hrm/CarUse/CarUseDetailsComponent')

export default [
    {
        path:'/CarUseIndex',
        name:'CarUseIndex',
        component:CarUseView,
    },
    {
        path:'/CarUseDetails',
        name:'CarUseDetails',
        component:CarUseDetails
    }
]