const OverTimeView = () => import('@/views/hrm/OverTime/OverTimeView')
const OverTimeDetails = () => import('@/components/hrm/OverTime/OverTimeDetailsComponent')

export default [
    {
        path:'/OverTimeIndex',
        name:'OverTimeIndex',
        component:OverTimeView,
    },
    {
        path:'/OverTimeDetails',
        name:'OverTimeDetails',
        component:OverTimeDetails
    }
]