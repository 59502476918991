const CarDriverAppointStaffView = () => import('@/views/WareHouse/CarDriverAppointStaff/CarDriverAppointStaffView')
const CarDriverAppointStaffDetails = () => import('@/components/WareHouse/CarDriverAppointStaff/CarDriverAppointStaffDetailsComponent')

export default [
    {
        path:'/CarDriverAppointStaffIndex',
        name:'CarDriverAppointStaffIndex',
        component:CarDriverAppointStaffView
    },
    {
        path:'/CarDriverAppointStaffDetails',
        name:'CarDriverAppointStaffDetails',
        component:CarDriverAppointStaffDetails
    }
]