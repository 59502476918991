const StoreroomView = () => import('@/views/WareHouse/Storeroom/StoreroomView')
const StoreroomDetails = () => import('@/components/WareHouse/Storeroom/StoreroomDetailsComponent')

export default [
    {
        path:'/StoreroomIndex',
        name:'StoreroomIndex',
        component:StoreroomView
    },
    {
        path:'/StoreroomDetails',
        name:'StoreroomDetails',
        component:StoreroomDetails
    }
]