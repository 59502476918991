const BusinessPaymentView = () => import('@/views/finance/BusinessPayment/BusinessPaymentView')
const BusinessPaymentDetails = () => import('@/components/finance/BusinessPayment/BusinessPaymentDetailsComponent')

export default [
    {
        path:'/BusinessPaymentIndex',
        name:'BusinessPaymentIndex',
        component:BusinessPaymentView,
    },
    {
        path:'/BusinessPaymentDetails',
        name:'BusinessPaymentDetails',
        component:BusinessPaymentDetails
    }
]