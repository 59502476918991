const ReimbursementCategoryView = () => import('@/views/finance/ReimbursementCategory/ReimbursementCategoryView')
const ReimbursementCategoryDetails = () => import('@/components/finance/ReimbursementCategory/ReimbursementCategoryDetailsComponent')

export default [
    {
        path:'/ReimbursementCategoryIndex',
        name:'ReimbursementCategoryIndex',
        component:ReimbursementCategoryView
    },
    {
        path:'/ReimbursementCategoryDetails',
        name:'ReimbursementCategoryDetails',
        component:ReimbursementCategoryDetails
    }
]