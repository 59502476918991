const PurchaseView = () => import('@/views/finance/Purchase/PurchaseView')
const PurchaseDetails = () => import('@/components/finance/Purchase/PurchaseDetailsComponent')

export default [
    {
        path:'/PurchaseIndex',
        name:'PurchaseIndex',
        component:PurchaseView,
    },
    {
        path:'/PurchaseDetails',
        name:'PurchaseDetails',
        component:PurchaseDetails
    }
]