import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: 'https://o.yushengtong.cn/',
    loading: false,
    user: {
      my_vacation:{}
    },
    authList: [],
    active: 4,
    my_nav_bar_name: '我的',
    my_nav_bar_icon: 'al-icon-icon_wode',
    state: [{id: 0,name: '待提交'},{id: 1,name: '审批中'},{id: 2,name: '已通过'},{id: 3,name: '已驳回'}],
    examine_type: [
      {
        id: 0,
        name: '采购单申请'
      },{
        id: 1,
        name: '招待单申请'
      },{
        id: 2,
        name: '备用金申请'
      },{
        id: 3,
        name: '入库单申请'
      },{
        id: 4,
        name: '出库单申请'
      },{
        id: 5,
        name: '移库单申请'
      },{
        id: 6,
        name: '差旅单申请'
      },{
        id: 7,
        name: '费用报销单申请'
      },{
        id: 8,
        name: '休假申请'
      },{
        id: 9,
        name: '仓库账单申请'
      },{
        id: 10,
        name: '业务付款申请'
      },{
        id: 11,
        name: '外出申请'
      },{
        id: 12,
        name: '加班申请'
      },{
        id: 13,
        name: '补签申请'
      },{
        id: 14,
        name: '合同申请'
      },{
        id: 15,
        name: '合作企业申请'
      },{
        id: 16,
        name: '中远电商订单申请'
      },{
        id: 17,
        name: '印章使用申请'
      },{
        id: 19,
        name: '公务车使用申请'
      }
    ]
  },
  mutations: {
    edit_user(state, data) {
      state.user = data
    },
    false_loading(state) {
      state.loading = false
    },
    true_loading(state) {
      state.loading = true
    },
    edit_auth_list(state, data) {
      state.authList = data
    },
    edit_active(state,data) {
      state.active = data.active
      state.my_nav_bar_name = data.my_nav_bar_name
      state.my_nav_bar_icon = data.my_nav_bar_icon
    }
  },
  actions: {
  },
  modules: {
  }
})
