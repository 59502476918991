const ProductView = () => import('@/views/WareHouse/Product/ProductView')
const ProductDetails = () => import('@/components/WareHouse/Product/ProductDetailsComponent')

export default [
    {
        path:'/ProductIndex',
        name:'ProductIndex',
        component:ProductView
    },
    {
        path:'/ProductDetails',
        name:'ProductDetails',
        component:ProductDetails
    }
]