const PettyView = () => import('@/views/finance/Petty/PettyView')
const PettyDetails = () => import('@/components/finance/Petty/PettyDetailsComponent')

export default [
    {
        path:'/PettyIndex',
        name:'PettyIndex',
        component:PettyView,
    },
    {
        path:'/PettyDetails',
        name:'PettyDetails',
        component:PettyDetails
    }
]