const ReimbursementView = () => import('@/views/finance/Reimbursement/ReimbursementView')
const ReimbursementDetails = () => import('@/components/finance/Reimbursement/ReimbursementDetailsComponent')

export default [
    {
        path:'/ReimbursementIndex',
        name:'ReimbursementIndex',
        component:ReimbursementView,
    },
    {
        path:'/ReimbursementDetails',
        name:'ReimbursementDetails',
        component:ReimbursementDetails
    }
]