const LocationView = () => import('@/views/WareHouse/Location/LocationView')
const LocationDetails = () => import('@/components/WareHouse/Location/LocationDetailsComponent')

export default [
    {
        path:'/LocationIndex',
        name:'LocationIndex',
        component:LocationView
    },
    {
        path:'/LocationDetails',
        name:'LocationDetails',
        component:LocationDetails
    }
]