const ClientView = () => import('@/views/WareHouse/Client/ClientView')
const ClientDetails = () => import('@/components/WareHouse/Client/ClientDetailsComponent')

export default [
    {
        path:'/ClientIndex',
        name:'ClientIndex',
        component:ClientView
    },
    {
        path:'/ClientDetails',
        name:'ClientDetails',
        component:ClientDetails
    }
]