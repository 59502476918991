import Vue from 'vue'
import VueRouter from 'vue-router'

const MainView = () => import('@/views/MainView')
const LoginView = () => import('@/views/login/LoginView')
const IndexView = () => import('@/views/index/IndexView')
const WXLoginView = () => import('@/views/login/WXLoginView')

//我的
import UserInformationRouter from './user/UserInformationRouter'
import UserPassWordRouter from './user/UserPassWordRouter'
import UserEmailRouter from './user/UserEmailRouter'
import UserPhoneRouter from './user/UserPhoneRouter'
import UserNoticeRouter from './user/UserNoticeRouter'
import UserAvatarRouter from './user/UserAvatarRouter'
import UserApplyRouter from './user/UserApplyRouter'
import UserExamineRouter from './user/UserExamineRouter'
import UserCcRouter from './user/UserCcRouter'
//公共
import ApprovalRouter from './common/ApprovalRouter'
//仓储
import StoreroomRouter from './WareHouse/Storeroom/StoreroomRouter'
import LocationRouter from './WareHouse/Location/LocationRouter'
import StockRouter from './WareHouse/Stock/StockRouter'
import ClientRouter from './WareHouse/Client/ClientRouter'
import ClientStockRateRouter from './WareHouse/ClientStockRate/ClientStockRateRouter'
import ProductRouter from './WareHouse/Product/ProductRouter'
import CarDriverAppointStaffRouter from './WareHouse/CarDriverAppointStaff/CarDriverAppointStaffRouter'
//财务
import BankCardRouter from './finance/BankCard/BankCardRouter'
import PurchaseRouter from './finance/Purchase/PurchaseRouter'
import EntertainRouter from './finance/Entertain/EntertainRouter'
import EntertainClientRouter from './finance/EntertainClient/EntertainClientRouter'
import TravelRouter from './finance/Travel/TravelRouter'
import TravelAddressRouter from './finance/TravelAddress/TravelAddressRouter'
import PettyRouter from './finance/Petty/PettyRouter'
import ReimbursementRouter from './finance/Reimbursement/ReimbursementRouter'
import ReimbursementCategoryRouter from './finance/ReimbursementCategory/ReimbursementCategoryRouter'
import BusinessPaymentRouter from './finance/BusinessPayment/BusinessPaymentRouter'
import ContractRouter from './finance/Contract/ContractRouter'
import CurrencyRouter from './finance/Currency/CurrencyRouter'
import CarUseRouter from './hrm/CarUseRouter'
//考勤
import VacationRouter from './hrm/VacationRouter'
import GoOutRouter from './hrm/GoOutRouter'
import OverTimeRouter from './hrm/OverTimeRouter'
import SupplementClockInRouter from './hrm/SupplementClockInRouter'
//商务
import CooperativeEnterpriseRouter from './business/CooperativeEnterpriseRouter'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: MainView,
  children:[
    {
      path:'',
      name:'IndexView',
      component:IndexView
    }
  ].concat( //我的
    UserInformationRouter,
    UserPassWordRouter,
    UserEmailRouter,
    UserPhoneRouter,
    UserNoticeRouter,
    UserAvatarRouter,
    UserApplyRouter,
    UserExamineRouter,
    UserCcRouter
  ).concat( //公共
    ApprovalRouter
  ).concat( //仓储
    StoreroomRouter,
    LocationRouter,
    StockRouter,
    ClientRouter,
    ClientStockRateRouter,
    ProductRouter,
    CarDriverAppointStaffRouter
  ).concat( //财务
    BankCardRouter,
    PurchaseRouter,
    EntertainRouter,
    EntertainClientRouter,
    TravelRouter,
    TravelAddressRouter,
    PettyRouter,
    ReimbursementRouter,
    ReimbursementCategoryRouter,
    BusinessPaymentRouter,
    ContractRouter,
    CurrencyRouter,
    CarUseRouter
  ).concat( //考勤
    VacationRouter,
    GoOutRouter,
    OverTimeRouter,
    SupplementClockInRouter
  ).concat( //商务
  CooperativeEnterpriseRouter
)
},{
  path:'/wx_login',
  name:'WXLoginView',
  component:WXLoginView
},{
  path:'/login',
  name:'LoginView',
  component:LoginView
}]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//解决重复访问同一个路由报错的问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
    //服务器
    if ((!sessionStorage.getItem('x-token')) && (to.path !== '/wx_login')) {
      //不存在token的时侯，微信回调登录
      // location.replace('/WXlogin?from='+to.fullPath)
      location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx38c1f2f378352604&redirect_uri=https://mobile.yushengtong.cn/wx_login&response_type=code&scope=snsapi_base#wechat_redirect')
      return
    }

    //本地
    // if (to.path === '/login') {
    //   if (sessionStorage.getItem('x-token')) {
    //     //跳转到首页
    //     location.replace('/')
    //     return
    //   }
    // } else {
    //   if (!sessionStorage.getItem('x-token')) {
    //     //跳转到登录页面
    //     location.replace('/login')
    //     return
    //   }
    // }
  next()
})

export default router
