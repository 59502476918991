const TravelView = () => import('@/views/finance/Travel/TravelView')
const TravelDetails = () => import('@/components/finance/Travel/TravelDetailsComponent')

export default [
    {
        path:'/TravelIndex',
        name:'TravelIndex',
        component:TravelView,
    },
    {
        path:'/TravelDetails',
        name:'TravelDetails',
        component:TravelDetails
    }
]