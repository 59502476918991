const ClientStockRateView = () => import('@/views/WareHouse/ClientStockRate/ClientStockRateView')
const ClientStockRateDetails = () => import('@/components/WareHouse/ClientStockRate/ClientStockRateDetailsComponent')

export default [
    {
        path:'/ClientStockRateIndex',
        name:'ClientStockRateIndex',
        component:ClientStockRateView
    },
    {
        path:'/ClientStockRatenDetails',
        name:'ClientStockRateDetails',
        component:ClientStockRateDetails
    }
]