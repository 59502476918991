const BankCardView = () => import('@/views/finance/BankCard/BankCardView')
const BankCardDetails = () => import('@/components/finance/BankCard/BankCardDetailsComponent')

export default [
    {
        path:'/BankCardIndex',
        name:'BankCardIndex',
        component:BankCardView,
    },
    {
        path:'/BankCardDetails',
        name:'BankCardDetails',
        component:BankCardDetails
    }
]