const VacationView = () => import('@/views/hrm/vacation/VacationView')
const VacationDetails = () => import('@/components/hrm/vacation/VacationDetailsComponent')

export default [
    {
        path:'/VacationIndex',
        name:'VacationIndex',
        component:VacationView,
    },
    {
        path:'/VacationDetails',
        name:'VacationDetails',
        component:VacationDetails
    }
]