const TravelAddressView = () => import('@/views/finance/TravelAddress/TravelAddressView')
const TravelAddressDetails = () => import('@/components/finance/TravelAddress/TravelAddressDetailsComponent')

export default [
    {
        path:'/TravelAddressIndex',
        name:'TravelAddressIndex',
        component:TravelAddressView,
    },
    {
        path:'/TravelAddressDetails',
        name:'TravelAddressDetails',
        component:TravelAddressDetails
    }
]