const CooperativeEnterpriseView = () => import('@/views/business/CooperativeEnterprise/CooperativeEnterpriseView')
const CooperativeEnterpriseDetails = () => import('@/components/business/CooperativeEnterprise/CooperativeEnterpriseDetailsComponent')

export default [
    {
        path:'/CooperativeEnterpriseIndex',
        name:'CooperativeEnterpriseIndex',
        component:CooperativeEnterpriseView,
    },
    {
        path:'/CooperativeEnterpriseDetails',
        name:'CooperativeEnterpriseDetails',
        component:CooperativeEnterpriseDetails
    }
]