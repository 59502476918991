const SupplementClockInView = () => import('@/views/hrm/SupplementClockIn/SupplementClockInView')
const SupplementClockInDetails = () => import('@/components/hrm/SupplementClockIn/SupplementClockInDetailsComponent')

export default [
    {
        path:'/SupplementClockInIndex',
        name:'SupplementClockInIndex',
        component:SupplementClockInView,
    },
    {
        path:'/SupplementClockInDetails',
        name:'SupplementClockInDetails',
        component:SupplementClockInDetails
    }
]