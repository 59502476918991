const GoOutView = () => import('@/views/hrm/GoOut/GoOutView')
const GoOutDetails = () => import('@/components/hrm/GoOut/GoOutDetailsComponent')

export default [
    {
        path:'/GoOutIndex',
        name:'GoOutIndex',
        component:GoOutView,
    },
    {
        path:'/GoOutDetails',
        name:'GoOutDetails',
        component:GoOutDetails
    }
]