const EntertainView = () => import('@/views/finance/Entertain/EntertainView')
const EntertainDetails = () => import('@/components/finance/Entertain/EntertainDetailsComponent')

export default [
    {
        path:'/EntertainIndex',
        name:'EntertainIndex',
        component:EntertainView,
    },
    {
        path:'/EntertainDetails',
        name:'EntertainDetails',
        component:EntertainDetails
    }
]