const EntertainClientView = () => import('@/views/finance/EntertainClient/EntertainClientView')
const EntertainClientDetails = () => import('@/components/finance/EntertainClient/EntertainClientDetailsComponent')

export default [
    {
        path:'/EntertainClientIndex',
        name:'EntertainClientIndex',
        component:EntertainClientView
    },
    {
        path:'/EntertainClientDetails',
        name:'EntertainClientDetails',
        component:EntertainClientDetails
    }
]